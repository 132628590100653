.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .wizard {
    max-width: 650px;
    width: auto;
    /* margin: 4rem auto 0; */
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    overflow: scroll;
    display: grid;
    grid-template-rows: 1fr 35px;
    grid-template-areas:
      "content"
      "buttons";
  }
  
  .wizard__content {
    grid-area: content;
    padding: 1rem;
    background: #eeeeee;
    padding-top: 1rem;
    box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.3);
  }
  
  .wizard__buttons {
    grid-area: buttons;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    padding-bottom: 1rem;
  }
  
  .wizard__buttons-left {
    grid-area: left;
    float: left;
  }
  
  .wizard__buttons-right {
    grid-area: right;
    float: right;
  }
  