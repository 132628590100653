.icon-box{
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .modal-content * {
    border: none;
  }
  /* // created by reactstrap ModalHeader */
  .modal-title, .modal-footer {
    margin: 0 auto;
  }
  .modal-body {
    padding-top: 0px;
  }
  
  .modal-header .close {
      position: absolute;
          top: -2px;
          right: 2px;
      }
  
  .red-circle{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    padding: 5px;
    font-size: 60px;
    color:red;
    transition:0.2s;
    background-color: white;
    border: 3px solid red;
  }