.listg{
    /* background-color: rgb(240, 240, 240); */
    box-shadow: 1px 1px 1px 1px rgb(218, 218, 218);
    cursor: pointer;
    width: 100%;
    
  }
  .listg:hover{
    background-color: rgb(233, 233, 233);
  }
  .textl{
    font-size: small;
  }

  .try:hover{
    background-color: #5927e3;
    color: white;
    border-radius: 50%;
    
}
.try{
    z-index: 5;
    position: absolute;
}