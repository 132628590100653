.App {
    font-family: sans-serif;
    text-align: center;
    width: 500px;
    margin: 0 auto;
  }
  
  .tabs {
    display: flex;
    list-style-type: none;
    background: #eee;
    padding: 3px 0;
  }
  
  .tabs .tabs-tab {
    padding: 5px 10px;
    color: #000;
    cursor: pointer;
  }
  
  .tabs .tabs-tab.active {
    background: orange;
  }
  
  .tabs .tabs-tab:hover {
    background: #777;
    color: #fff;
  }
  
  .tabs-tab-pane {
    padding: 10px;
    padding: 10px;
    margin: 10px;
    background: #eee;
  }